import { OrgCoupon, OrgRegistrationPackage } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function orgRegistrationPackage__client__addOrgRegistrationPackage(p: {
  registrationPackage: DistributiveOmit<OrgRegistrationPackage, "id" | "createdAtMS">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.OrgRegistrationPackage.add({
    doc: { ...p.registrationPackage, id: h.OrgRegistrationPackage.generateId(), createdAtMS: Date.now() }
  });
}
