import { OrgCouponId, OrgRegistrationPackageId, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftDocsSubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgRegistrationPackage__client__getMultiOrgRegistrationPackagesSubscription(p: { packageIds: string[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocsSubToBifrostSub(h.OrgRegistrationPackage.docsSubscription(p.packageIds));
}
