import { OrgCoupon, OrgRegistrationPackage } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
export async function orgRegistrationPackage__client__updateOrgRegistrationPackage(p: {
  registrationPackage: OrgRegistrationPackage;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.OrgRegistrationPackage.set({
    id: p.registrationPackage.id,
    doc: p.registrationPackage
  });
}
