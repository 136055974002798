import { OrgCouponId, OrgId, Org, OrgRegistrationPackageId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftDocSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export async function orgRegistrationPackage__client__getOrgRegistrationPackage(p: { id: OrgRegistrationPackageId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  if (!p.id) {
    return null;
  }

  return await h.OrgRegistrationPackage.getDoc(p.id);
}
