import { OrgCoupon, OrgCouponId, OrgRegistrationPackageId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { BatchTask } from "@ollie-sports/firebase";
export async function orgRegistrationPackage__client__deleteOrgRegistrationPackage(p: { id: OrgRegistrationPackageId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const orgRegistrationPackage = await h.OrgRegistrationPackage.getDoc(p.id);
  if (!orgRegistrationPackage) {
    throw new Error("Can't delete a registration package that doesn't exist.");
  }
  const assignedTeams = (
    await h.TeamSettings.query({
      where: [
        {
          registrationPackageIdBySeasonId: { [orgRegistrationPackage.orgSeasonId]: ["==", p.id] }
        }
      ]
    })
  ).docs;

  const batchTasks: BatchTask[] = [];
  batchTasks.push(await h.OrgRegistrationPackage.delete({ id: p.id }, { returnBatchTask: true }));
  for (let i = 0; i < assignedTeams.length; i++) {
    batchTasks.push(
      await h.TeamSettings.setPath(
        {
          id: assignedTeams[i].id,
          pathObj: { registrationPackageIdBySeasonId: { [orgRegistrationPackage.orgSeasonId]: true } },
          value: { registrationPackageIdBySeasonId: { [orgRegistrationPackage.orgSeasonId]: h._MagicDeleteValue } }
        },
        { returnBatchTask: true }
      )
    );
  }

  await h._BatchRunner.executeBatch(batchTasks);
}
