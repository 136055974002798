import { getUniversalHelpers } from "../../helpers";

const IMAGE_DIRECTORY = "org_registration_packages/";

export async function orgRegistrationPackage__client__uploadImage(p: { name: string; file: any }) {
  const { app } = getUniversalHelpers();
  return app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + Date.now() + p.name)
    .put(p.file);
}

// i18n certified - complete
